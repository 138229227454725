import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, interval, Observable, Subject} from 'rxjs';
import {UmsatzService as KuUmsatzService} from '../../services/umsatz.service';
import {distinctUntilChanged, filter, map, takeUntil} from 'rxjs/operators';
import {FilterUmsatzDTO, InhaberDTO, UmsatzService} from '../../openapi/kontoumsatz-openapi';
import {NavigationService} from '@adnova/jf-ng-components';


@Injectable({
  providedIn: 'root'
})
export class OverviewService implements OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private inhaberId$ = new BehaviorSubject<string | undefined>(undefined);

  private umsaetzeToAssignCount$ = new BehaviorSubject<number>(-1);

  constructor(
    private navigationService: NavigationService,
    private umsatzService: UmsatzService,
    private kuUmsatzService: KuUmsatzService,
  ) {
    this.navigationService.currentInhaber$.pipe(
      takeUntil(this.unsubscribe$),
      map(inhaber => inhaber?.id),
      filter((inhaberId): inhaberId is string => !!inhaberId),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.inhaberId$.next(value as string);
    });

    this.navigationService.currentInhaber$.pipe(
      takeUntil(this.unsubscribe$),
      map(inhaber => inhaber?.id),
      filter((inhaberId): inhaberId is string => !!inhaberId),
      distinctUntilChanged(),
    ).subscribe(value => {
      const inhaberId = value as string;
      this.updateUmsaetzeToAssignCount(inhaberId);
    });

    // refresh counts every 5 minutes
    interval(300_000).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      const inhaberId = this.inhaberId$.value;
      if (inhaberId) {
        this.updateUmsaetzeToAssignCount(inhaberId);
      }
    });

    // INFO: Das Badge aktualisieren, wenn eine Zuordnung stattgefunden hat
    this.kuUmsatzService.finishedAssignment.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(() => {
      const inhaberId = this.inhaberId$.value;
      if (inhaberId) {
        this.updateUmsaetzeToAssignCount(inhaberId);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  updateUmsaetzeToAssignCount(
    inhaberId: string,
  ): void {
    if (this.umsaetzeToAssignCount$.value !== -1) {
      this.umsaetzeToAssignCount$.next(-1);
    }

    const filter: FilterUmsatzDTO = {
      abgeschlossen: false,
    };
    this.umsatzService.countUmsaetze(inhaberId, filter)
      .pipe(
        map(value => value.count),
      )
      .subscribe(value => {
        this.umsaetzeToAssignCount$.next(value);
      });
  }

  /**
   * Gibt die Gesamtanzahl der unbearbeiteten Kontoumsätze alle Bankkonten des Inhabers zurück
   */
  get umsaetzeToAssignCount(): Observable<number> {
    return this.umsaetzeToAssignCount$.asObservable();
  }
}
